'use client';

import { memo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { Account } from 'components/Account';
import { useAuth } from 'hooks/auth/useAuth';
import { Box } from 'uikitv2/Box';
import classes from './Header.module.scss';
import { Logo } from './Logo';
import { Menu } from './Menu';

export const Header = memo(() => {
  const { isUserConnected } = useAuth();
  const router = useRouter();

  const onClick = useCallback((e) => {
    e.preventDefault();
    router.push('/', undefined);
  }, [router]);

  return (
    <Box className={classes.wrap} Tag="header" data-testid="header" justifyContent="center">
      <Box className={classes.content} alignItems="center" justifyContent="space-between">
        <Box className={classes.menu} alignItems="center">
          <Logo {...{ onClick }} />
          <Menu {...{ isUserConnected }} />
        </Box>
        <Account />
      </Box>
    </Box>
  );
});

export default Header;