import { Storage } from 'components/Account/types';

export const settings = {
  id: 'storage',
  title: 'Storage',
  data: [
    {
      value: Storage.SP_CLOUD,
      label: 'Super Protocol cloud',
    }, {
      value: Storage.STORJ_ACCOUNT,
      label: 'Your StorJ account',
    },
  ],
};
