'use client';

import {
  memo, useState, useCallback, useMemo,
} from 'react';
import dynamic from 'next/dynamic';

import { Box } from 'uikitv2/Box';
// import { DocInfoIcon } from 'uikitv2/DocInfoIcon';
import { CheckConfidentiality } from 'components/CheckConfidentiality';
import { Providers as ProvidersSecretKeeper } from 'lib/features/secretKeeper/types';
import { useAuth } from 'hooks/auth/useAuth';
import { Events } from './AccountDropdown/types';
import { DemoAccount } from './DemoAccount';
import { Web3Account } from './Web3Account';
import { AccountBalance } from './AccountBalance';
import { getItems } from './helpers';
import { AccountModal } from './types';
import classes from './Account.module.scss';

const AccountDropdown = dynamic(() => import('./AccountDropdown/AccountDropdown'), { ssr: false });

export const Account = memo(() => {
  const {
    user, logout, provider, isUserConnected, loading,
  } = useAuth();
  const [accountModal, setAccountModal] = useState<AccountModal | null>();

  const onCloseAccountModal = useCallback(() => {
    setAccountModal(null);
  }, []);

  const onSelect = useCallback((value: any) => {
    switch (value) {
      case Events.DISCONNECT:
        logout();
        break;
      case Events.ACCOUNT:
        if (provider) {
          setAccountModal(
            [ProvidersSecretKeeper.metamask].includes(provider as ProvidersSecretKeeper) ? AccountModal.WEB3 : AccountModal.DEMO,
          );
        }
        break;
      default:
        break;
    }
  }, [logout, provider]);

  const items = useMemo(() => getItems(user, provider), [user, provider]);

  return (
    <Box alignItems="center">
      {/* <DocInfoIcon href={docLink} className={classes.mr} /> */}
      {isUserConnected && <AccountBalance className={classes.mr} />}
      <AccountDropdown
        value={provider}
        items={items}
        onSelect={onSelect}
        hasUser={isUserConnected}
        loading={loading}
      />
      {isUserConnected && (
        <CheckConfidentiality />
      )}
      <DemoAccount show={accountModal === AccountModal.DEMO} onClose={onCloseAccountModal} user={user} provider={provider} />
      <Web3Account show={accountModal === AccountModal.WEB3} onClose={onCloseAccountModal} user={user} />
    </Box>
  );
});

export default Account;