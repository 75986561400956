import { UserSettings } from 'lib/features/secretKeeper/types';
import { useAuth } from 'hooks/auth/useAuth';
import { useGetUserSettingsQuery } from 'lib/features/secretKeeper/api';

export interface UseSecretKeeperUserSettings {
  data?: UserSettings;
  loading: boolean;
  refetch: () => void;
}

export const useSecretKeeperUserSettings = (): UseSecretKeeperUserSettings => {
  const { isUserConnected, isProviderSecretKeeper } = useAuth();
  const response = useGetUserSettingsQuery(
    null,
    { skip: !isUserConnected || !isProviderSecretKeeper, refetchOnMountOrArgChange: true },
  );

  return {
    data: response.data,
    loading: response.isLoading,
    refetch: response.refetch,
  };
};