export const popperConfig = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 3],
      },
    },
  ],
};
