import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { v1 as uuid } from 'uuid';

import { Theme } from 'uikitv2/common/types';
import { isSSR } from 'utils';
import { RadioProps } from './types';
import classes from './Radio.module.scss';

export const Radio = ({
  id: idProp,
  label,
  checked = false,
  value,
  disabled,
  onChange = () => {},
  isInvalid,
  name,
  classNameContainer,
  classNameCheckmark,
  classNameLabel,
  theme = Theme.dark,
}: RadioProps) => {
  const [id, setId] = useState<string>('');

  useEffect(() => {
    if (!isSSR() && !id) {
      setId(idProp ?? uuid());
    }
  }, [id, idProp]);

  return (
    <label
      className={cn(classes.container, classes[theme], classNameContainer, { [classes.container_disabled]: disabled })}
      htmlFor={id}
    >
      <input
        id={id}
        name={name}
        checked={checked}
        type="radio"
        value={value}
        disabled={disabled}
        className={classes.input}
        onChange={onChange}
      />
      <span className={cn(classes.checkmark, { [classes.checkmark_invalid]: isInvalid }, classNameCheckmark)} />
      {!!label && (
        <span className={cn(classes.label, { [classes.label_invalid]: isInvalid }, classNameLabel)}>
          {label}
        </span>
      )}
    </label>
  );
};
