import { memo } from 'react';
import { usePathname } from 'next/navigation';
import cn from 'classnames';
import Link from 'next/link';
import classes from './NavLink.module.scss';
import { NavLinkProps } from './types';

export const NavLink = memo(({ href, className, children }: NavLinkProps) => {
  const pathname = usePathname();
  const isActive = pathname.startsWith(href);

  return (
    <Link href={href} prefetch={false} className={cn(classes.link, className, { [classes.active]: isActive })}>
      {children}
    </Link>
  );
});