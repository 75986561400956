import { memo, forwardRef } from 'react';
import { Popover } from 'react-bootstrap';
import cn from 'classnames';

import classes from '../Tooltip.module.scss';
import { TooltipPopoverProps } from './types';

export const TooltipPopover = memo(forwardRef<HTMLDivElement, TooltipPopoverProps>(({
  popoverProps,
  overlayProps,
  hideArrow,
  classNamePopover,
  onMouseEnter,
  onMouseLeave,
  id,
  dataTestId,
  classNamePopoverText,
  tooltip,
}, ref) => {
  return (
    <Popover
      {...overlayProps}
      {...popoverProps}
      {...(hideArrow ? { arrowProps: { style: { display: 'none' }, ref: null as any } } : {})} /* todo any */
      className={cn(classes.popover, classNamePopover)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      id={`popover-positioned-${id}`}
      data-testid={dataTestId}
    >
      <Popover.Body className={cn(classes.body, classNamePopoverText)} ref={ref}>
        {tooltip || ''}
      </Popover.Body>
    </Popover>
  );
}));
