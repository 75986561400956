import { memo } from 'react';
import ModalBootstrap from 'react-bootstrap/Modal';
import cn from 'classnames';

import { Icon } from 'uikitv2/Icon';
import { Theme } from 'uikitv2/common/types';
import classes from './Modal.module.scss';
import { ModalProps } from './types';

export const Modal = memo<ModalProps>(({
  dialogClassName,
  contentClassName,
  backdropClassName,
  bodyClassName,
  children,
  onClose,
  showShadow = false,
  theme = Theme.dark,
  dataTestId = 'modal-window',
  ...props
}) => {
  return (
    <ModalBootstrap
      size="sm"
      centered
      dialogClassName={cn(classes.dialog, dialogClassName, classes[`root_${theme}`])}
      backdropClassName={cn(classes.backdrop, backdropClassName, classes[`backdrop_${theme}`])}
      contentClassName={cn(classes.content, contentClassName, { [classes.shadow]: showShadow })}
      className={classes.modal}
      data-testid={dataTestId}
      {...props}
    >
      <ModalBootstrap.Body className={cn(classes.body, bodyClassName)} data-testid={`${dataTestId}-body`}>
        {!!onClose && (
          <Icon
            name="close"
            className={classes['icon-close']}
            onClick={onClose}
            data-testid={`${dataTestId}-close`}
          />
        )}
        {children}
      </ModalBootstrap.Body>
    </ModalBootstrap>
  );
});

export default Modal;
