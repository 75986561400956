import { validateRTKResponse } from 'lib/features/helpers';
import {
  useLazyCreateCentralizedStorageQuery,
  useLazyGetStoragesQuery,
  useLazyGetUserSettingsQuery,
  useLazyCreateStorageQuery,
  useLazyGetStorageQuery,
} from 'lib/features/spProvider/api';
import { useCallback } from 'react';
import { Storage } from 'lib/features/spProvider/types';

export interface UseLazySPStorage {
  createCentralizedStorage: ReturnType<typeof useLazyCreateCentralizedStorageQuery>[0];
  createStorage: ReturnType<typeof useLazyCreateStorageQuery>[0];
  getStorages: ReturnType<typeof useLazyGetStoragesQuery>[0];
  getStorage: ReturnType<typeof useLazyGetStorageQuery>[0];
  getActiveStorage: () => Promise<Storage | null | undefined>
}

export const useLazySPStorage = (): UseLazySPStorage => {
  const [createCentralizedStorage] = useLazyCreateCentralizedStorageQuery();
  const [createStorage] = useLazyCreateStorageQuery();
  const [getUserSettings] = useLazyGetUserSettingsQuery();
  const [getStorages] = useLazyGetStoragesQuery();
  const [getStorage] = useLazyGetStorageQuery();
  const getActiveStorage = useCallback(async () => {
    const settings = validateRTKResponse(await getUserSettings(null), { skipStatuses: [404] });
    const storageId = settings.data?.activeStorageId;
    if (!storageId) return null;
    return validateRTKResponse(await getStorage(storageId))?.data || null;
  }, [getUserSettings, getStorage]);

  return {
    createCentralizedStorage,
    getStorages,
    getStorage,
    getActiveStorage,
    createStorage,
  };
};