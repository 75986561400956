import React, { forwardRef } from 'react';
import cn from 'classnames';

import { Icon } from 'uikitv2/Icon';
import classes from './DropdownToggle.module.scss';
import { DropdownToggleProps } from './types';

export const DropdownToggle = forwardRef<HTMLDivElement, DropdownToggleProps>(({
  children, className, classNameIcon, onClick, showArrow = true, id, disabled,
}, ref) => {
  return (
    <div
      className={cn(classes.btn, className)}
      ref={ref}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          onClick?.(e);
        }
      }}
      onKeyDown={() => {}}
      data-toggle="dropdown"
      role="button"
      tabIndex={0}
      id={id}
    >
      {children}
      {showArrow && <Icon name="triangle" className={cn(classes.icon, classNameIcon)} />}
    </div>
  );
});
