import Image from 'next/image';
import { socialIcons } from 'components/Login/helpers';

import { Box } from 'uikit/Box';
import classes from './Web3Account.module.scss';

export const title = 'Web3 Account';

const {
  alt, height, label, src, width,
} = socialIcons.metamask;

export const getAccountData = (id?: number | string | null, address?: string | null) => {
  if (!id || !address) return [];

  return [{
    label: 'User ID',
    data: id,
  }, {
    label: 'Login',
    data: (
      <Box alignItems="center">
        <Image
          {...{
            alt, height, width, label, src,
          }}
          className={classes.imgIcon}
        />
        <span>{address}</span>
      </Box>
    ),
  }];
};

export const settings = [
  {
    id: 'storage',
    title: 'Storage',
    data: [
      {
        value: 'sp_cloud',
        label: 'Super Protocol cloud',
      }, {
        value: 'storj_account',
        label: 'Your StorJ account',
      },
    ],
  }, {
    id: 'provider',
    title: 'Provider',
    data: [
      {
        value: 'sp_provider',
        label: 'Super Protocol provider',
      }, {
        value: 'own_provider',
        label: 'Your own provider',
      },
    ],
  },
];
