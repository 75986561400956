import React, { memo } from 'react';
import { useField } from 'formik';

import { Radio } from './Radio';
import { isChecked } from './helpers';
import { RadioFormikProps } from './types';

export const RadioFormik = memo(({
  name,
  value,
  onChange = () => {},
  ...rest
}: RadioFormikProps) => {
  const [, meta, { setValue }] = useField(name);
  const { value: valueFormik } = meta;

  return (
    <Radio
      {...meta}
      {...rest}
      checked={isChecked(value, valueFormik)}
      onChange={(event) => {
        setValue((event.target as HTMLInputElement).value);
        onChange(event);
      }}
      {...{ value, name }}
    />
  );
});
