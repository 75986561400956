import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import cn from 'classnames';
import { v1 as uuidv1 } from 'uuid';

import { Box } from 'uikit/Box';
import getConfig from 'config';
import { addSuccessNotification } from 'lib/features/notifications';
import { useAppDispatch } from 'lib/hooks';
import { Spinner } from 'uikit/Spinner';
import { MessageTypes } from './types';
import classes from './CheckConfidentialityModal.module.scss';

const { NEXT_PUBLIC_CHECKCONFIDENTIALITY_API } = getConfig();

export const CheckConfidentialityModal = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const [height, setHeight] = useState(95);

  const eventHandler = useCallback((e) => {
    if (e.origin !== NEXT_PUBLIC_CHECKCONFIDENTIALITY_API) {
      return;
    }
    if (e.data.type === MessageTypes.height) {
      setHeight(e.data.height);
    }
    if (e.data.type === MessageTypes.copy) {
      dispatch(addSuccessNotification('key copied'));
    }
  }, [dispatch]);

  const onLoad = useCallback(() => {
    window.addEventListener(
      'message',
      eventHandler,
      false,
    );
    setIsLoaded(true);
  }, [eventHandler]);

  useEffect(() => {
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, [eventHandler]);

  const src = useMemo(() => `${NEXT_PUBLIC_CHECKCONFIDENTIALITY_API}?random=${uuidv1()}`, []);

  return (
    <Box direction="column" className={cn({ [classes.box]: !isLoaded })}>
      {!isLoaded && (
        <Box alignItems="center" justifyContent="center" className={classes.filler}>
          <Box>
            <span>Please, wait...</span>
            <Spinner
              animation="border"
              className={classes.spinner}
            />
          </Box>
        </Box>
      )}
      <iframe
        title="content"
        src={src}
        className={classes.frame}
        onLoad={onLoad}
        height={isLoaded ? height : 0}
        role="application"
        scrolling="no"
      />
    </Box>
  );
};
