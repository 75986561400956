import { useState, useCallback } from 'react';

import { InputFormik } from 'uikitv2/Input';
import { IconSwitcher } from './IconSwitcher';
import { InputWrapperProps } from './types';
import classes from '../../../Web3Account.module.scss';
import { InputType } from '../types';

export const InputWrapper = ({
  disabled, isValidating, label, name,
}: InputWrapperProps) => {
  const [type, setType] = useState(InputType.password);

  const onClickIcon = useCallback(() => {
    setType((prev) => (prev === InputType.password ? InputType.text : InputType.password));
  }, []);

  const append = useCallback(() => <IconSwitcher inputType={type} onClick={onClickIcon} />, [onClickIcon, type]);

  return (
    <InputFormik
      label={label}
      name={name}
      checkTouched={!isValidating}
      type={type}
      classNameLabel={classes['input-label']}
      disabled={disabled}
      append={append}
    />
  );
};
