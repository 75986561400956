import { Storage } from 'lib/features/spProvider/types';
import { useAuth } from 'hooks/auth/useAuth';
import { useGetStorageQuery } from 'lib/features/spProvider/api';

export interface UseSPStorage {
  data?: Storage;
  loading: boolean;
  refetch: () => void;
}

export const useSPStorage = (storage?: string): UseSPStorage => {
  const { isUserConnected, isProviderSPProvider } = useAuth();
  const storageResponse = useGetStorageQuery(
    storage as string,
    { skip: !isUserConnected || !isProviderSPProvider || !storage, refetchOnMountOrArgChange: true },
  );

  return {
    data: storageResponse?.data,
    loading: storageResponse.isLoading,
    refetch: storageResponse?.refetch,
  };
};