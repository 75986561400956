import { useAuth } from 'hooks/auth/useAuth';
import { useLazySPStorage, UseLazySPStorage } from './useLazySPStorage';
import { useLazySecretKeeperStorage, UseLazySecretKeeperStorage } from './useLazySecretKeeperStorage';

export type UseLazyStorage = UseLazySPStorage | UseLazySecretKeeperStorage;

export const useLazyStorage = (): UseLazyStorage => {
  const { isProviderSPProvider } = useAuth();
  const spStorage = useLazySPStorage();
  const secretKeeperStorage = useLazySecretKeeperStorage();
  return isProviderSPProvider ? spStorage : secretKeeperStorage;
};