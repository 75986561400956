export enum Provider {
  SP_PROVIDER = 'SP_PROVIDER',
  OWN_PROVIDER = 'OWN_PROVIDER',
}

export enum Storage {
  SP_CLOUD = 'SP_CLOUD',
  STORJ_ACCOUNT = 'STORJ_ACCOUNT',
}

export enum AccountModal {
  WEB3 = 'WEB3',
  DEMO = 'DEMO'
}
