import { memo, FC, useMemo } from 'react';
import cn from 'classnames';
import { useAppSelector } from 'lib/hooks';
import { Box } from 'uikit/Box';
import { Spinner } from 'uikit/Spinner';
import { getFixedDeposit } from 'utils/sdk/utils';
import { themeSelector } from 'lib/features/theme';
import { useAuth } from 'hooks/auth/useAuth';
import classes from './AccountBalance.module.scss';
import { AccountBalanceProps } from './types';

export const AccountBalance: FC<AccountBalanceProps> = memo(({ className }) => {
  const {
    balance, loadingBalance, isProviderSecretKeeper, isProviderSPProvider,
  } = useAuth();
  const { matic, tee } = balance || {};
  const theme = useAppSelector(themeSelector);

  const maticFixed = useMemo(
    () => (matic ? getFixedDeposit({ deposit: matic }) : '-'),
    [matic],
  );

  const teeFixed = useMemo(
    () => (tee ? getFixedDeposit({ deposit: tee }) : '-'),
    [tee],
  );

  return (
    <Box className={cn(classes.balance, className, classes[theme])}>
      {(!matic || !tee) && loadingBalance && (
        <Spinner fullscreen classNameWrap={classes.spinnerWrap} />
      )}
      {isProviderSecretKeeper && (
        <span className={classes.text} data-testid="account-balance">
          {maticFixed} MATIC / {teeFixed} TEE
        </span>
      )}
      {isProviderSPProvider && <span className={classes.text} data-testid="account-balance">{teeFixed} TEE</span>}
    </Box>
  );
});