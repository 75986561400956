import { Item } from 'uikit/Dropdown/types';
import getConfig from 'config';
import { Events } from './types';

const {
  NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS, NEXT_PUBLIC_NETWORK_SCAN, NEXT_PUBLIC_DOCS,
} = getConfig();

export const getItems = (): Item[] => {
  return [
    {
      label: 'View in explorer',
      href: `${NEXT_PUBLIC_NETWORK_SCAN}/address/${NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS || ''}`,
      external: true,
    },
    { label: 'Check Confidentiality', value: Events.Check },
    {
      label: 'Documentation',
      href: NEXT_PUBLIC_DOCS,
      external: true,
    },
    {
      label: 'Contact Us',
      href: 'https://superprotocol.zendesk.com/hc/en-us/requests/new',
      external: true,
    },
  ];
};
