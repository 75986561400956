import { useAuth } from 'hooks/auth/useAuth';
import { useLazySPUserSettings, UseLazySPUserSettings } from './useLazySPUserSettings';
import { useLazySecretKeeperUserSettings, UseLazySecretKeeperUserSettings } from './useLazySecretKeeperUserSettings';

export type UseLazyUserSettings = UseLazySPUserSettings | UseLazySecretKeeperUserSettings;

export const useLazyUserSettings = (): UseLazyUserSettings => {
  const { isProviderSPProvider } = useAuth();
  const spUserSettings = useLazySPUserSettings();
  const secretKeeperUserSettings = useLazySecretKeeperUserSettings();
  return isProviderSPProvider ? spUserSettings : secretKeeperUserSettings;
};