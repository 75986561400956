import * as Yup from 'yup';
import { Storage } from 'components/Account/types';
import { Fields } from './types';

export const placeholder = 'Enter a passphrase here...';

export const getPrefixSchema = (): Yup.BaseSchema => Yup.string().test(
  Fields.prefix,
  'path contains invalid characters',
  (str = '') => {
    const validChars = /^[\p{L}\p{N}!\-_*'()/]+$/u;
    const prefix = str?.trim();
    if (!prefix) return true;
    return validChars.test(prefix);
  },
) as Yup.BaseSchema;

export const getValidationSchema = (): any => {
  return Yup.object({
    [Fields.bucket]: Yup.string().required('Required'),
    [Fields.prefix]: getPrefixSchema(),
    [Fields.readAccessKeyId]: Yup.string().required('Required'),
    [Fields.readSecretAccessKey]: Yup.string().required('Required'),
    [Fields.writeAccessKeyId]: Yup.string().required('Required'),
    [Fields.writeSecretAccessKey]: Yup.string().required('Required'),
  });
};

export const settings = {
  id: 'storage',
  title: 'Storage',
  data: [
    {
      value: Storage.SP_CLOUD,
      label: 'Super Protocol cloud',
    }, {
      value: Storage.STORJ_ACCOUNT,
      label: 'Your StorJ account',
    },
  ],
};

export const formatStoragePrefix = (prefix: string): string => {
  let formattedPrefix = prefix;

  if (formattedPrefix.length === 0) {
    return formattedPrefix;
  }

  if (!formattedPrefix.endsWith('/')) {
    formattedPrefix += '/';
  }

  formattedPrefix = formattedPrefix.replace(/\/{2,}/g, '/');

  if (formattedPrefix.startsWith('/')) {
    return formattedPrefix.substring(1);
  }

  return formattedPrefix;
};
