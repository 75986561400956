import React, {
  memo, useState, SyntheticEvent, useCallback,
} from 'react';
import cn from 'classnames';
import { v1 as uuidv1 } from 'uuid';

import { Box } from 'uikitv2/Box';
import { Notification } from 'uikitv2/Notification';
import { CheckboxCheckMark } from './CheckboxCheckMark';
import { CheckboxProps } from './types';
import classes from './Checkbox.module.scss';

export const Checkbox = memo<CheckboxProps>(({
  label,
  checked = false,
  disabled,
  disabledInput,
  semi,
  onChange = () => {},
  onClick = () => {},
  isInvalid,
  error,
  name,
  classNameWrap,
  classNameLabel,
  classNameCheckboxCheckmark,
  labelClickDisabled = true,
  showError,
  align = 'center',
  description,
  ...rest
}) => {
  const [nameFor] = useState(name || uuidv1());
  const handleOnClick = useCallback((e: SyntheticEvent) => {
    if (!labelClickDisabled) {
      e.preventDefault();
      onClick();
    }
  }, [labelClickDisabled, onClick]);

  return (
    <Box direction="column" className={classNameWrap}>
      {description && (
        <span className={classes.description}>{description}</span>
      )}
      <label
        className={cn(classes.checkbox, classes[align], { [classes.disabled]: disabled })}
        htmlFor={nameFor}
      >
        <input
          id={nameFor}
          name={nameFor}
          checked={checked}
          type="checkbox"
          disabled={disabled || disabledInput}
          className={classes.checkbox__control}
          onChange={(evt) => {
            if (!disabled) {
              onChange(evt.target.checked, evt);
            }
          }}
          {...rest}
        />
        <CheckboxCheckMark
          {...{
            classNameCheckboxCheckmark,
            isInvalid,
            disabled: (disabled || disabledInput),
            semi,
          }}
        />
        <span
          className={cn(
            classes.checkbox__label,
            classNameLabel,
            { [classes.checkbox__label_invalid]: isInvalid },
            { [classes.checkbox__label_disabled]: disabled },
          )}
          onClick={handleOnClick}
          onKeyDown={() => {}}
          tabIndex={0}
          role="button"
        >
          {label}
        </span>
      </label>
      {showError && isInvalid && error && (
        <Notification className={classes.notification} variant="red">{error}</Notification>
      )}
    </Box>
  );
});

export default Checkbox;
