import { forwardRef } from 'react';
import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { Icon } from 'uikitv2/Icon';
import { NotificationProps } from './types';
import { iconNameByVariant } from './helpers';
import classes from './Notification.module.scss';

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(({
  children, variant, className, classNameIcon, hasIcon = true, ...props
}, ref) => {
  return (
    <Box
      className={cn(
        classes.root,
        classes?.[variant],
        className,
      )}
      justifyContent="flex-start"
      alignItems="flex-start"
      ref={ref}
      {...props}
    >
      {hasIcon && (
        <Icon
          name={iconNameByVariant[variant]}
          className={cn(classes.icon, classes[variant], classNameIcon)}
          fill="none"
        />
      )}
      {children}
    </Box>
  );
});
