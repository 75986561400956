import { Box } from 'uikitv2/Box';
import classes from './DividerWithText.module.scss';
import { DividerWithTextProps } from './types';

export const DividerWithText = ({ text }: DividerWithTextProps) => (
  <Box className={classes.content} alignItems="center">
    <div className={classes.line} />
    <span className={classes.text}>{text}</span>
    <div className={classes.line} />
  </Box>
);
