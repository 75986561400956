import { Item } from 'uikitv2/Dropdown/types';

export interface ExtraData {
  icon: string;
  alt: string;
  width: number;
  height: number;
}

export interface AccountDropdownProps {
  onSelect?: (value: any) => void;
  hasUser: boolean;
  items: Item<any, ExtraData>[];
  value: any;
  loading?: boolean;
}

export enum Events {
  DISCONNECT = 'DISCONNECT',
  ACCOUNT = 'ACCOUNT',
}