import React, { forwardRef } from 'react';
import cn from 'classnames';

import { DropdownMenuProps } from './types';
import classes from './DropdownMenu.module.scss';

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(({
  children,
  className,
  'aria-labelledby': labeledBy,
}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(classes.wrap, className)}
      aria-labelledby={labeledBy}
    >
      {React.Children.toArray(children)}
    </div>
  );
});
