import { memo } from 'react';

import { Icon } from 'uikitv2/Icon';
import { IconSwitcherProps } from './types';
import { iconName } from './helpers';
import classes from './IconSwitcher.module.scss';

export const IconSwitcher = memo<IconSwitcherProps>(({ inputType, onClick }) => (
  <Icon
    name={iconName[inputType]}
    onClick={onClick}
    fill="none"
    className={classes.icon}
  />
));
