import { createSelector } from '@reduxjs/toolkit';
import { formSelector } from '.';
import {
  Steps, Form, FieldsBuildOrderForm,
} from './types';
import { getFormBuildOffersCount } from './helpers';

export const buildOrderFormSelector = createSelector(
  formSelector,
  (form: Form) => form?.[Steps.BUILD_ORDER],
);

export const createPassphraseFormSelector = createSelector(
  formSelector,
  (form: Form) => form?.[Steps.CREATE_PASSPHRASE],
);

export const formContentSelector = createSelector(
  formSelector,
  (form: Form) => {
    const buildOrderForm = form?.[Steps.BUILD_ORDER] || {};
    return {
      [FieldsBuildOrderForm.model]: buildOrderForm[FieldsBuildOrderForm.model],
      [FieldsBuildOrderForm.engine]: buildOrderForm[FieldsBuildOrderForm.engine],
      [FieldsBuildOrderForm.datasets]: buildOrderForm[FieldsBuildOrderForm.datasets],
      [FieldsBuildOrderForm.compute]: buildOrderForm[FieldsBuildOrderForm.compute],
    };
  },
);

export const formBuildOffersCountSelector = createSelector(
  buildOrderFormSelector,
  getFormBuildOffersCount,
);