import {
  useState,
  useRef,
  useMemo,
  memo,
  FC,
  useEffect,
  useCallback,
  useId,
} from 'react';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { ButtonToolbar, Overlay } from 'react-bootstrap';

import { Theme } from 'uikitv2/common/types';
import { TooltipPopover } from './TooltipPopover';
import { TooltipProps } from './types';
import { popperConfig } from './helpers';
import classes from './Tooltip.module.scss';

export const Tooltip: FC<TooltipProps> = memo(({
  children,
  placement = 'top',
  tooltip,
  delay = 300,
  className,
  classNamePopover,
  classNamePopoverText,
  popoverProps = {},
  buttonToolbarProps = {},
  initialShow,
  dataTestId,
  classNamePopoverChildren,
  onMouseLeave: onMouseLeaveProp,
  onMouseEnter: onMouseEnterProp,
  hideArrow,
  noMargin = false,
  theme = Theme.dark,
  containerRef = null,
}) => {
  const [show, setShow] = useState(initialShow);
  const cursorInTooltip = useRef(false);
  const id = useId();
  const target = useRef(null);
  const onMouseEnter = useCallback(() => {
    if (tooltip) {
      cursorInTooltip.current = true;
      setShow(true);
      onMouseEnterProp?.();
    }
  }, [onMouseEnterProp, tooltip]);
  const onMouseLeaveDebounce = useDebouncedCallback(() => {
    if (!cursorInTooltip.current) {
      setShow(false);
      onMouseLeaveProp?.();
    }
  }, delay);
  const onMouseLeave = useCallback(() => {
    cursorInTooltip.current = false;
    onMouseLeaveDebounce();
  }, [onMouseLeaveDebounce]);
  const childrenProps = useMemo(() => ({
    ref: target,
    onMouseEnter,
    onMouseLeave,
  }), [onMouseEnter, onMouseLeave, target]);
  useEffect(() => {
    setShow(initialShow);
  }, [initialShow]);
  return (
    <ButtonToolbar
      className={cn(classes.control, className)}
      {...buttonToolbarProps}
    >
      <Overlay
        placement={placement}
        target={target.current}
        show={show}
        container={containerRef ?? target.current}
        transition={false}
        popperConfig={popperConfig}
      >
        {(props) => {
          if (!tooltip) return null;
          return (
            <TooltipPopover {...{
              popoverProps,
              overlayProps: props,
              hideArrow,
              classNamePopover: cn(classNamePopover, classes[theme]),
              onMouseEnter,
              onMouseLeave,
              id,
              dataTestId,
              classNamePopoverText: cn(classNamePopoverText, classes[theme]),
              tooltip,
            }}
            />
          );
        }}
      </Overlay>
      <div className={cn(classes.content, { [classes.noMargin]: noMargin }, classNamePopoverChildren)} {...childrenProps}>
        {children}
      </div>
    </ButtonToolbar>
  );
});
