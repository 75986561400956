import { UserSettings } from 'lib/features/spProvider/types';
import { useAuth } from 'hooks/auth/useAuth';
import { useGetUserSettingsQuery } from 'lib/features/spProvider/api';

export interface UseSPUserSettings {
  data?: UserSettings;
  loading: boolean;
  refetch: () => void;
}

export const useSPUserSettings = (): UseSPUserSettings => {
  const { isUserConnected, isProviderSPProvider } = useAuth();
  const response = useGetUserSettingsQuery(
    null,
    { skip: !isUserConnected || !isProviderSPProvider, refetchOnMountOrArgChange: true },
  );

  return {
    data: response.data,
    loading: response.isLoading,
    refetch: response.refetch,
  };
};