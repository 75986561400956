import { useAuth } from 'hooks/auth/useAuth';
import { UseSPStorage, useSPStorage } from './useSPStorage';
import { UseSecretKeeperStorage, useSecretKeeperStorage } from './useSecretKeeperStorage';

export type UseStorage = UseSPStorage | UseSecretKeeperStorage;

export const useStorage = (storage?: string): UseStorage => {
  const { isProviderSPProvider } = useAuth();
  const spStorage = useSPStorage(storage);
  const secretKeeperStorage = useSecretKeeperStorage(storage);
  return isProviderSPProvider ? spStorage : secretKeeperStorage;
};