import { useAuth } from 'hooks/auth/useAuth';
import { useSPUserSettings, UseSPUserSettings } from './useSPUserSettings';
import { useSecretKeeperUserSettings, UseSecretKeeperUserSettings } from './useSecretKeeperUserSettings';

export type UseUserSettings = UseSPUserSettings | UseSecretKeeperUserSettings;

export const useUserSettings = (): UseUserSettings => {
  const { isProviderSPProvider } = useAuth();
  const spUserSettings = useSPUserSettings();
  const secretKeeperUserSettings = useSecretKeeperUserSettings();
  return isProviderSPProvider ? spUserSettings : secretKeeperUserSettings;
};