import cn from 'classnames';

import { DividerProps } from './types';
import classes from './Divider.module.scss';

export const Divider = ({ className, orientation = 'horizontal' }: DividerProps) => {
  return (
    <div className={cn(classes.line, classes[orientation], className)} />
  );
};
