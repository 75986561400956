import { Box } from 'uikit/Box';
import { Radio } from 'uikit/Radio';
import classes from '../Web3Account.module.scss';
import { RadioGroupProps } from './types';

export const RadioGroup = ({
  data, id, onChange, selected, title, disabled,
}: RadioGroupProps) => {
  return (
    <>
      <h3 className={classes.title}>{title}</h3>
      <Box className={classes.radioGroup} direction="column">
        {data.map(({ value, label }) => (
          <Radio
            disabled={disabled}
            key={value}
            value={value}
            checked={selected === value}
            label={label}
            onChange={onChange}
            classNameContainer={classes.container}
            name={id}
          />
        ))}
      </Box>
    </>
  );
};
