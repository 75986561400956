import {
  useLazyGetUserSettingsQuery,
  useLazyUpdateUserSettingsQuery,
  useLazyCreateUserSettingsQuery,
} from 'lib/features/secretKeeper/api';

export interface UseLazySecretKeeperUserSettings {
  createUserSettings: ReturnType<typeof useLazyCreateUserSettingsQuery>[0];
  getUserSettings: ReturnType<typeof useLazyGetUserSettingsQuery>[0];
  updateUserSettings: ReturnType<typeof useLazyUpdateUserSettingsQuery>[0];
}

export const useLazySecretKeeperUserSettings = (): UseLazySecretKeeperUserSettings => {
  const [createUserSettings] = useLazyCreateUserSettingsQuery();
  const [updateUserSettings] = useLazyUpdateUserSettingsQuery();
  const [getUserSettings] = useLazyGetUserSettingsQuery();

  return {
    createUserSettings,
    updateUserSettings,
    getUserSettings,
  };
};