import {
  useCallback, useMemo, useState, memo,
} from 'react';

import { Dropdown } from 'uikitv2/Dropdown';
import { Modal } from 'uikitv2/Modal';
import { Icon } from 'uikitv2/Icon';
import { SquareButton } from 'uikitv2/Buttons/SquareButton';
import { CheckConfidentialityModal } from './CheckConfidentialityModal';
import { Events } from './types';
import { getItems } from './helpers';
import classes from './CheckConfidentiality.module.scss';

export const CheckConfidentiality = memo(() => {
  const [showCheckConfidentialityModal, setShowCheckConfidentialityModal] = useState(false);
  const items = useMemo(() => getItems(), []);

  const renderToggleContent = useCallback(() => {
    return (
      <SquareButton
        variant="obscure"
        className={classes.btn}
        data-testid="button-check-confidentiality"
      >
        <Icon name="app" />
      </SquareButton>
    );
  }, []);
  const onCloseResultModal = useCallback(async () => {
    setShowCheckConfidentialityModal(false);
  }, []);
  const onSelect = useCallback((value: any) => {
    if (value === Events.Check) {
      setShowCheckConfidentialityModal(true);
    }
  }, []);

  return (
    <>
      <Modal {...{
        show: showCheckConfidentialityModal,
        onClose: onCloseResultModal,
        dialogClassName: classes.dialog,
        bodyClassName: classes['body-modal'],
        contentClassName: classes['content-modal'],
      }}
      >
        <CheckConfidentialityModal />
      </Modal>
      <Dropdown
        items={items}
        renderToggleContent={renderToggleContent}
        className={classes.content}
        classNameDropdownToggle={classes['dropdown-toggle']}
        classNameDropdownMenu={classes['dropdown-menu']}
        classNameMenuItem={classes['dropdown-menu-item']}
        onSelect={onSelect}
        dataTestId="dropdown-check-confidentiality"
        showArrow={false}
        id="check-confidentiality"
      />
    </>
  );
});

export default CheckConfidentiality;
