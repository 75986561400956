import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { ModalTitle } from 'uikitv2/Modal/ModalTitle';
import { DataBlockProps } from './types';
import classes from './DataBlock.module.scss';

export const DataBlock = ({ title, accountData, className }: DataBlockProps) => (
  <>
    <ModalTitle title={title} />
    <Box direction="column" className={cn(classes['block-data'], className)}>
      {accountData.map(({ label, data }) => (
        <Box className={classes.line} key={label}>
          <span className={classes.label}>{label}:</span>
          {data}
        </Box>
      ))}
    </Box>
  </>
);
